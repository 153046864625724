@import './mixins';
.anchor-menu {
    @include text-sm;
    position: fixed;
    left: 24px;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    justify-content: center;
    align-items: left;
    z-index: 100;
  }
  
  .anchor-item {
    display: flex;
    gap: 12px;
  
    &.in-view {
      .anchor-id {
        border: 1px solid $gray-25;
      }
    }
  
    &:hover {
      .anchor-id {
        border: 1px solid $gray-25;
      }
  
      .anchor-title {
        opacity: 100%;
        color: $gray-50;
      }
    }
  }
  
  .anchor-id {
    width: 24px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 1px;
    border: 1px solid rgba(27, 36, 51, 0); // Start with no border
    border-radius: 100%;
    transition: border 300ms ease;
    color: $gray-50;
  }
  
  .anchor-title {
    opacity: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
    transition: opacity 300ms ease;
  }

  .initials {
    display: none;
  }



  @media (max-width: 1024px) {
    .anchor-title {
      display: none;
    }
  }
  
  @media (max-width: 860px) {
    .anchor-menu {
      display: flex;
      position: sticky;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100vw;
      background-color: #fdfdfd;
      padding: 8px 96px;
      z-index: 10;
      border-bottom: 1px solid $gray-5;
    }

    .anchor-id {
      padding-top: 2px;
      height: 22px;
    }

    .initials {
      display: flex;
      height: 24px;
      width: 24px;
      padding-top: 2px;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 560px) {
    .anchor-menu {
      padding: 8px 24px;
    }
  }