@font-face {
  font-family: "Favorit";
  src: url("../fonts/EduFavorit-Book.woff") format("woff");
  font-weight: normal;
}

@import "./mixins";

.caps {
  text-transform: uppercase;
}



html {
  font-size: 16px;
  background-color: #fdfdfd;
  scroll-behavior: smooth;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: Favorit, -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #1b2433;
}

::selection {
  background-color: #EDEDE3;
}
/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

.me {
  margin-bottom: 24px;
}

p {
  margin-top: 0;
  margin-bottom: 24px;
}
/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

a {
  text-decoration: none;
  color: $gray-75;

  &:pressed {
    color: $gray-50;
  }
}

.header {
  @include grid-container;
  margin-top: 64px;
  margin-bottom: 240px;

  .intro {
    a {
      transition: box-shadow 150ms ease;
      box-shadow: inset 0 -1px 0 0 $gray-25;
      padding-bottom: 1px;
    }
  }

  .contact {
    a {
      opacity: 1;
      color: $gray-75;
    }
  }
}

.end {
  @include grid-container;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  position: relative;

  p {
    @include text-sm;
    grid-column-start: 7;
    grid-column-end: 11;
    text-align: center;
    color: $gray-75;
    margin-top: 0;
    margin-bottom: 16px;
    max-width: 360px;
  }


.handwriting {
  width: 240px;
  position: absolute;
  bottom: 48px;
  right: 48px;
}

  a.underline {
    box-shadow: inset 0 -1px 0 0 $gray-25;
    padding-bottom: 2px;
  }

  a {
    transition: box-shadow 150ms ease;
    padding-bottom: 2px;

    &:hover {
      box-shadow: inset 0 -1px 0 0 $gray-25;
    }
  }

  
}

.intro {
  @include text-md;
  @include grid-item-narrow;
  margin-bottom: 24px;
  color: $gray-75;
}

.columns {
  @include text-sm;
  grid-column-start: 5;
  grid-column-end: 13;
  display: flex;
  gap: 4px;

  a {
    transition: box-shadow 150ms ease;
    padding-bottom: 2px;

    &:hover {
      box-shadow: inset 0 -1px 0 0 $gray-25;
    }
  }

  .col-title {
    @include text-sm;
    padding-bottom: 24px;
    margin: 0;
    color: $gray-50;
  }

  .experience,
  .education {
    flex: 3;

    .row {
      display: flex;
      gap: 4px;
      padding-bottom: 16px;

      .year {
        flex: 1;
        color: $gray-75;
      }

      .item {
        flex: 2;
        color: $gray-75;
        padding-bottom: 2px;
      }

      .sub-item {
        color: $gray-50;
      }
    }
  }

  .more {
    flex: 2;

    .col-title {
      padding-bottom: 0;
    }

    .item {
      color: $gray-75;
      padding-bottom: 16px;
    }

    .resume > a {
      color: $gray-50;
    }
    .arrow {
      width: 10px;
      height: 10px;
      margin-left: 4px;
    }
  }
}

.project {
  @include grid-container;
  margin-bottom: 280px;
  transition: filter 0.4s ease;
}

.project {
  &.blur {
    filter: blur(8px);
    opacity: 0;
    transition: filter 0.4s, opacity 0.4s;
  }
}

.project-carousel {
  @include grid-item-wide;
  padding-bottom: 32px;
  position: relative;
  width: 100%;
  max-width: 100%;
  aspect-ratio: 5 / 3;
  overflow: hidden;
  position: relative;
}

.carousel-left {
  background: rgba(0, 0, 0, 0);
}

.carousel-right {
  background: rgba(0, 0, 0, 0);
}

.project-video {
  width: 100%;
  // height: 100%;
  object-fit: cover;
  position: absolute;
}
 

.project-image, .project-video {
  width: 100%;
  object-fit: contain;
  transition: filter 0.2s ease, opacity 0.2s ease;
}

.project-image.transitory, .project-video.transitory {
  filter: blur(8px);
  opacity: 0; /* Adjust opacity during transition */
  transition: opacity 0.4s ease;
}

.project-pagination {
  @include text-sm;
  position: absolute;
  top: 16px;
  right: 16px;
  color: rgba(255,255,255,0.6);
  padding-bottom: 2px;
  border-bottom: solid 1px rgba(255,255,255,0.4);
  mix-blend-mode: exclusion;
}

.lock {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: -16px;
    width: 32px;
    height: 40px;
  }
}

.project-text {
  @include grid-item-narrow;
  color: $gray-75;
}

.project-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
}

.greyed-out {
  color: $gray-25;
  transition: color 0.3s ease;

  &:hover {
    color: $gray-50;
  }
}
.project-title,
.project-nda {
  @include text-sm();
  color: $gray-50;
  margin-bottom: 24px;
}

.project-nda {
  padding: 2px 4px 0px 4px;
  border: 1px solid $gray-25;
  border-radius: 4px;
  display: none;
}

.project-description {
  @include text-md();
  margin-bottom: 48px;
  margin-top: 0;

  a {
    transition: box-shadow 150ms ease;
    box-shadow: inset 0 -1px 0 0 $gray-25;
    padding-bottom: 1px;
  }
}

.project-metadata {
  @include text-sm;
  display: flex;
  gap: 4px;

  .metadata {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .metadata-header {
    color: $gray-50;
  }

  a.collaborator {
    transition: box-shadow 150ms ease;
    padding-bottom: 2px;

    &:hover {
      box-shadow: inset 0 -1px 0 0 $gray-25;
    }
  }
}

.content-toggle {
  @include text-sm;
  color: $gray-50;
  margin-left: 8px;
  transition: opacity 300ms ease;
  padding: 2px 4px 0px 4px;
  border: 1px solid $gray-25;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 50%;
  }
}

@media (max-width: 1024px) {
  .header {
    margin-bottom: 160px;
  }
  .columns {
    @include text-sm;
    grid-column-start: 4;
    grid-column-end: 14;
    display: flex;
    gap: 4px;

    .col-title {
      @include text-sm;
      padding-bottom: 24px;
      margin: 0;
      color: $gray-50;
    }

    .experience,
    .education {
      flex: 4;

      .row {
        display: flex;
        gap: 4px;
        padding-bottom: 16px;

        .year {
          flex: 1;
          color: $gray-75;
        }

        .item {
          flex: 2;
          color: $gray-75;
        }

        .sub-item {
          color: $gray-50;
        }
      }
    }

    .more {
      flex: 3;
      opacity: 80%;

      .col-title {
        color: $gray-50;
        padding-bottom: 0;
      }
    }
  }

  .project-carousel {
    margin-left: 0px;
  }

  .lock {
    img {
      margin-top: -8px;
      width: 24px;
      height: 30px;
    }
  }
}



@media (max-width: 860px) {
  html,
  body {
    // overflow-x: hidden;
  }

  .header {
    margin-top: 48px;
    margin-bottom: 120px;
  }

  .columns {
    @include text-sm;
    grid-column-start: 1;
    grid-column-end: 9;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .col-title {
      @include text-sm;
      padding-bottom: 24px;
    }

    .experience,
    .education {
      .row {
        padding-bottom: 10px;

        .year {
          flex: 2;
        }

        .item {
          flex: 6;
        }
      }
    }

    .more {
      flex: 2;
      color: $gray-75;

      .resume {
        margin-top: 30px;
      }

      .col-title {
        color: $gray-50;
      }
    }
  }

  .gallery {
    margin-top: 48px;
  }

  .project {
    margin-bottom: 196px;
  }

  .project-title {
    // display: none;
  }

  .project-carousel {
    padding-bottom: 32px;
  }

  .project-description {
    margin-top: 0;
    margin-bottom: 32px;
  }

  .project-metadata {
    flex-direction: column;
    gap: 12px;

    .metadata {
      flex-direction: row;
      gap: 4px;
    }

    .metadata-header {
      flex: 2;
    }

    .metadata-value {
      flex: 6;
    }
  }

  .lock {
    img {
      margin-top: -16px;
      width: 24px;
      height: 30px;
    }
  }
}

@media (max-width: 560px) {
  .header {
    margin-top: 24px;
    margin-bottom: 120px;
  }

  .project-carousel {
    padding-bottom: 24px;
  }

  .columns {
    .col-title {
      padding-bottom: 24px;
    }

    .experience,
    .education {
      flex: 3;

      .row {
        .year {
          flex: 3;
        }

        .item {
          flex: 5;
        }
      }
    }
  }

  .project {
    &.blur {
      filter: blur(8px);
      opacity: 0;
      transition: filter 0.4s, opacity 0.4s;
    }
  }

  .project-metadata {
    flex-direction: column;
    gap: 12px;

    .metadata {
      flex-direction: row;
      gap: 4px;
    }

    .metadata-header {
      flex: 3;
    }

    .metadata-value {
      flex: 5;
    }
  }

  .content-toggle {
    padding: 2px 4px 0px 4px;
  }

  .lock {
    img {
      margin-top: -16px;
      width: 20px;
      height: 28px;
    }
  }
}
