$gray-75: rgba(27,36,51,0.75);
$gray-50: rgba(27,36,51,0.5);
$gray-25: rgba(27,36,51,0.25);
$gray-5: rgba(27,36,51,0.05);

@mixin text-sm {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 148%;
    text-align: left;
  }
  
  @mixin text-md {
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 160%;
    text-align: left;

    @media (max-width: 560px) {
      font-size: 1rem;
    }
  }
  
  @mixin text-lg {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 148%;
    text-align: left;
  }
  
  @mixin grid-container {
    display: grid;
    position: relative;
    grid-template-columns: repeat(16, 1fr);
    column-gap: 4px;
    margin-left: 4px;
    margin-right: 4px;

    @media (max-width: 860px) {
      grid-template-columns: repeat(8, 1fr);
      margin-left: 96px;
      margin-right: 96px;
    }

    @media (max-width: 560px) {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
  
  @mixin grid-item-wide {
    grid-column-start: 4;
    grid-column-end: 14;

    @media (max-width: 1024px) {
      grid-column-start: 3;
      grid-column-end: 15;
    }

    @media (max-width: 860px) {
      grid-column-start: 1;
      grid-column-end: 9;
      margin: 0 -24px;
    }

    @media (max-width: 560px) {
      margin: 0 -12px;
    }
  }
  
  @mixin grid-item-narrow {
    grid-column-start: 5;
    grid-column-end: 13;

    @media (max-width: 1024px) {
      grid-column-start: 4;
      grid-column-end: 14;
    }

    @media (max-width: 860px) {
      grid-column-start: 1;
      grid-column-end: 9;
    }
  }